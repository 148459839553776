.project-list {
  width: 100%;
  height: calc(100vh - 45px);
  display: grid;
  gap: 20px;
  padding: 1.5rem;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-template-rows: repeat(auto-fill, 290px);
  overflow-y: auto;
}

.project-card {
  width: 100%;
  height: 290px;
  transition: all 0.3s ease;

  .project-cover {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: #606060;
    padding-left: 24px;
    padding-bottom: 12px;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.9;
    }

    &.no-location {
      background-color: #606060;
    }

    .name {
      color: white;
      font-size: 1.3em;
      font-weight: bold;
    }

    .location {
      color: white;
      font-size: 1em;
      text-transform: capitalize;
    }

    .date {
      margin-top: 3px;
      color: rgb(231, 231, 231);
      font-size: 0.8em;
    }
  }

  .ant-card-body {
    height: 190px;
  }

  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .project-description {
    display: block;
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.new-project {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 290px;
  color: #606060;
  cursor: pointer;
  padding-bottom: 50px;

  &:hover {
    opacity: 0.8;
  }

  p {
    margin: 1em;
    font-size: 1.1em;
    font-weight: bold;
  }
}

.project-add-icon {
  width: fit-content;
  height: fit-content;
  transition: all 0.3s ease;

  & > span {
    width: 100%;
    height: 100%;
  }
  & > svg {
    width: 50px;
    height: 50px;
    color: #dddddd;
  }
  &:hover {
    transform: scale(1.05);
  }
}

.property-display {
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  .name {
    color: darkgray;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 0.9rem;
  }

  .value {
    font-weight: bold;
    font-size: 1em;
    color: #606060;
  }
}
