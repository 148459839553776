.main-container {
  display: grid;
  grid-template-columns: 260px 1fr;
  height: calc(100vh - 45px);
  padding: 0;
  margin: 0;
  flex-grow: 0;
  flex-shrink: 0;
}
.local-container {
  display: flex;
  height: calc(100vh - 45px);
  padding: 20px;
  flex-grow: 0;
  flex-shrink: 0;
}

.sidebar-container {
  display: grid;
  grid-template-rows: 1fr auto;
  background-color: #ececec;
  border-right: rgb(185, 185, 185) solid 1px;
}

.empty-container {
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.warning-container {
  margin: 2rem;
}

.empty-title {
  padding-top: 30px;
  font-size: 14px;
  font-weight: bold;
}

.empty-body {
  font-size: 12px;
}

.version-title {
  margin-left: 10px;
  opacity: 0.5;
}

.versions-container {
  display: block;
  width: 100%;
  max-height: 60vh;
  overflow: auto;
}

.version-number {
  font-weight: bold;
  font-size: 11px;
}

.version-descriptions {
  margin-top: 10px !important;
}

.version-description {
  font-size: 12px;
  margin: 0;
  margin-bottom: 2px;
}

.version-warning {
  padding: 3px 6px !important;
  margin-bottom: 20px !important;
  font-size: 12px !important;
  font-weight: bold;
  color: #777777 !important;
}

.detail-container {
  display: block;
  min-width: 250px;
  padding: 1em;
}

.detail-header {
  display: inline-block;
  width: 100%;
  padding-bottom: 3px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.detail-header-text {
  padding-left: 5px;
  font-weight: 700;
}

.detail-body {
  display: inline-flex;
  width: 100%;
  padding-bottom: 3px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.detail-controls {
  padding-top: 10px;
  position: absolute;
  bottom: 20px;
  display: block;
  width: calc(100% - 20px);
}

.detail-body-text {
  padding-left: 5px;
  font-weight: 500;
  flex-grow: 1;
}

.detail-body-title {
  display: inline-block;
  min-width: 100px;
}

.draft-item {
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: 20px 1fr 20px 20px 20px;

  &.selected {
    font-weight: bold;
    color: #38ade2;
  }

  &:hover {
    color: #1890ff;
    cursor: pointer;

    .delete {
      color: red;
    }
  }
}

.value-input {
  width: 100%;
  border-radius: 2px;
  box-shadow: none;
  inset: none;
  border: none;
  padding: 0;
  cursor: text;
  height: 21px;

  &:focus {
    border: 1px solid rgba(34, 36, 38, 0.1);
    background: #f9fafb;
  }
}
