.load-form .ant-form-item {
  margin-bottom: 0px;
}

.horizontal-container {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.span-table {
  background: #222;
  overflow: hidden;
  width: 100%;
  margin-top: 1rem;
  border-radius: 2px;
  border: rgb(14, 14, 14);

  table {
    width: auto;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  th,
  td {
    padding: 4px;
    text-indent: 1em;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
  th {
    text-align: left;
  }
  thead th {
    background-color: #55608f;
  }

  tbody td {
    cursor: pointer;
  }
  tbody td:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .selected {
    font-weight: bold;
    background-color: rgba(255, 120, 120, 0.3);
  }
}

.slab-details {
  margin-top: 1rem;
  div {
    margin-bottom: 10px;
  }
}

.result-input {
  & > span {
    margin-right: 1em;
    margin-left: 1em;
  }
  & :first-child {
    margin-left: 0;
  }
}

.slab-tag {
  cursor: pointer;
}
