.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found-icon {
  font-size: 100px;
  font-weight: 600;
  padding-top: 150px;
}

.not-found-title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

.not-found-button {
  width: 200px;
}

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
  overflow: hidden;
}
