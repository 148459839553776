.login-section {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  background-color: #f5f5f5;
}

.login-container {
  padding-top: 15vh;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .tryout {
    width: 500px;
    height: 50px;
    display: grid;
    place-items: center;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    margin-bottom: 20px;
    transition: all ease-in-out 0.2s;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }

  .ant-tabs {
    width: 100%;
    max-width: 500px;
  }

  .logo {
    width: 200px;
    image-rendering: -webkit-optimize-contrast;
  }

  @media screen and (max-width: 500px) {
    padding-top: 5vh;
    .logo {
      width: 80px;
    }
  }
}

.login-form {
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.login-card {
  padding: 1em !important;
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  border-radius: 10px;

  .ant-tabs-content-holder {
    padding-top: 20px;
  }
}

.ant-tooltip-content {
  width: fit-content !important;
}
