.project-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.canvas-container {
  position: relative;
  background: #e8e8e8;
  outline: rgb(185, 185, 185) solid 1px;
}

.toolbar-container {
  height: 40px;
  background: #515151;
  width: 100%;
  color: white;
  padding-top: 7px;
  padding-left: 10px;
  padding-right: 10px;
}

.toolbar-container button {
  margin-right: 5px;
}

.log-container {
  padding: 5px 10px;
  font-size: 12px;
  background: #515151;
  color: white;
  width: 100%;
  height: 29px;
}

.infos {
  display: flex;
}

.info-container {
  margin: 0px 40px 10px 0px;
}

.info-title {
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

.info-value {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  box-sizing: border-box;
}

.error-container {
  position: absolute;
  top: 50px;
  left: 10px;
}
